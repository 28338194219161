// Arrows
$carousel-arrow-sizes: (
    default: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(40),
        arrow-icon-height: rem-calc(40),
        arrow-icon: default-right
    ),
    small: (
        arrow-width: rem-calc(30),
        arrow-height: rem-calc(30),
        arrow-icon-width: rem-calc(6),
        arrow-icon-height: rem-calc(11),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(24),
        arrow-height: rem-calc(24),
        arrow-icon-width: rem-calc(8),
        arrow-icon-height: rem-calc(12),
        arrow-icon: chevron2-right
    )
);

$carousel-arrow-border-radius: 50%;

// Pagination
$carousel-pagination-bullet-size: rem-calc(5);
$carousel-pagination-bullet-size-default--large: $carousel-pagination-bullet-size;
$carousel-pagination-bullet-horizontal-margin: rem-calc(0 10);
$carousel-pagination-bullet-horizontal-margin-small--large: $carousel-pagination-bullet-horizontal-margin;
$carousel-pagination-bullet-horizontal-margin-default--large: $carousel-pagination-bullet-horizontal-margin;
$carousel-pagination-bullet-size-small--large: $carousel-pagination-bullet-size;
$carousel-pagination-bullet-custom-enabled: true;
$carousel-pagination-margin: rem-calc(20 10);
$carousel-pagination-bullet-custom-width: rem-calc(25);
$carousel-pagination-bullet-custom-before-width: rem-calc(25);
$carousel-pagination-bullet-custom-before-border-radius: rem-calc(5);
$carousel-pagination-bullet-custom-before-background: color(disabled);
$carousel-pagination-bullet-custom-before-height: rem-calc(2);
$carousel-pagination-bullet-custom-height: rem-calc(4);
$carousel-pagination-bullet-custom-active-margin: rem-calc(0 6);
$carousel-pagination-bullet-custom-margin: rem-calc(0 4);

// Pagination space-reserve height
$carousel-pagination-space-reserve-small-height: rem-calc(21);

$carousel-bullet-sizes: (
    default: (
        bullet-size: $carousel-pagination-bullet-size,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin
    ),
    small: (
        bullet-size: rem-calc(7),
        bullet-margin: rem-calc(0 10)
    )
);

$carousel-bullet-sizes--large: (
    default: (
        bullet-size: $carousel-pagination-bullet-size-default--large,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin-default--large
    ),
    small: (
        bullet-size: $carousel-pagination-bullet-size-small--large,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin-small--large
    )
);

$carousel-arrow-hover-theme: (
    light: (
        background: none,
        color: color(dark)
    ),
);

@import "@lora/05-components/carousel";
