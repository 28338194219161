$product-main-aside-background-color--large: color(light);
$product-main-border: 0;
$product-main-image-padding--large: 0;

// Product name
$product-main-name-font: normal #{rem-calc(26)} / rem-calc(30) $font-secondary;
$product-main-name-font--large: 100 #{rem-calc(34)} / rem-calc(38) $font-secondary;
$product-main-name-text-transform: uppercase;
$product-main-name-margin: rem-calc(0 0 8 0);
$product-main-name-margin--large: $product-main-name-margin;

// Product subtitle
$product-main-subtitle-font: 100 #{rem-calc(14)} / rem-calc(16) $font-primary;
$product-main-subtitle-font--large: $product-main-subtitle-font;
$product-main-subtitle-color: color(text-secondary);
$product-main-subtitle-margin: rem-calc(0 0 10);

// Product Short Description
$product-main-short-description-readmore-font: 100 #{rem-calc(12)} / rem-calc(18) $font-primary;

// Product Rating
$product-main-rating-margin: rem-calc(0 0 10);
$product-main-rating-rating-icon-margin: rem-calc(4);

// Product Price
$product-main-price-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-primary;

// Product more shade toggle
$product-main__more-shade-width: auto;
$product-main__more-shade-width--large: auto;

// Recommendations Message
$product-main-recommendation-title-text-transform: none;
$product-main-recommendation-message-font: 500 #{rem-calc(14)} / 1.25 $font-primary;
$product-main-recommendation-message-color: color(primary);
$product-main-recommendation-message-margin--large: rem-calc(0 0 15);

// Recommendations Title
$product-main-recommendation-title-font: $product-main-recommendation-message-font;
$product-main-recommendation-title-font--large: $product-main-recommendation-title-font;

// Product Quantity Selector
$product-main-quantity-margin: rem-calc(0 5 0 0);

// Product icons
$product-main-icons-right: rem-calc(16);
$product-main-icons-right--large: rem-calc(20);
$product-main-icons-top: rem-calc(20);
$product-main-icons-top--large: 0;

// Product Rating
$product-main-rating-rating-font-size: rem-calc(16);
$product-main-review-count-font: 400 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-main-review-highlight-total-font-weight: 400;
$product-main-review-highlight-total-font: $product-main-review-highlight-total-font-weight #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-main-rating-margin: rem-calc(0 0 25);
$product-main-rating-margin--large: $product-main-rating-margin;

// Product Short Description
$product-main-short-description-font: 400 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-main-short-description-readmore-font: $product-main-short-description-font;
@import '@lora/05-components/product/product-main';