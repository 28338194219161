$product-availability-estimated-delivery-date-color: color(primary-active);
$product-availability-estimated-delivery-date-font: #{rem-calc(14)} / 1 $font-primary;
$product-availability-estimated-delivery-date-cart-item-color: color(primary-active);
$product-availability-estimated-delivery-date-cart-item-font: #{rem-calc(14)} / 1 $font-primary;
$product-availability-estimated-delivery-date-font--large: #{rem-calc(14)} / 1 $font-primary;
$product-availability-label-text-transform: none;
$product-availability-estimated-delivery-date-text-transform: $product-availability-label-text-transform;
$product-availability-estimated-delivery-date-cart-item-margin: rem-calc(10 0);

// Message
$product-availability-message-font: 300 #{rem-calc(12)} / rem-calc(16) $font-primary-light;
$product-availability-message-color: color(primary-active);
$product-availability-message-text-align: center;
$product-availability-message-background: color(global-background);

// Has background
$product-availability-label-has-background-padding: rem-calc(6 8 7);
$product-availability-label-has-background-border-radius: rem-calc(5);
$product-availability-label-has-background-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;

@import "@lora/05-components/product/product-availability";