$product-table-compare-cell-text-transform: uppercase;
$product-table-compare-cell-label-text-transform: none;
$product-table-compare-cell-label-border-bottom: 1px solid color(border);
$product-table-compare-cell-font: #{rem-calc(12)} / 1.5 $font-tertiary;
$product-table-compare-cell-padding: rem-calc(10 20);
$product-table-compare-cell-font--large: #{rem-calc(12)} / 1.5 $font-tertiary;
$product-table-compare-cell-label-font: #{rem-calc(10)} / 1.5 $font-primary;
$product-table-compare-remove-font: #{rem-calc(10)} / 1.8 $font-primary-light;
$product-table-compare-addmore-font: #{rem-calc(12)} / 1.3 $font-tertiary;
$product-table-compare-addmore-font--large: #{rem-calc(12)} / 1.3 $font-tertiary;
$product-table-compare-addmore-icon-background-color: color(global-background);
$product-table-compare-addmore-border: 1px solid color(border);
$product-table-compare-addmore-margin: rem-calc(20 0 0);
$product-table-compare-secondary-cell-padding--large: rem-calc(12 20);
$product-table-compare-addmore-margin--large: $product-table-compare-addmore-margin;

@import "@lora/05-components/product/product-table-compare";