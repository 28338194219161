$product-variations-carousel-link-align-items: center;
$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;
$product-variations-carousel-link-selected-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-selected-color: color(dark);
$product-variations-carousel-link-hover-background: color(secondary-active);
$product-variations-carousel-link-hover-color: color(light);
$product-variations-carousel-link-border: rem-calc(1) solid color(border);
$product-variations-carousel-link-border-radius: rem-calc(5);
$product-variations-carousel-link-hover-border: color(secondary-active);
$product-variations-carousel-link-selected-border: rem-calc(2) solid color(dark);
$product-variations-carousel-link-height: rem-calc(40);
$product-variations-carousel-link-min-height: rem-calc(60);
$product-variations-carousel-link-padding: rem-calc(14 10 13 10);
$product-variations-carousel-value-font-weight: 700;

// Selected state
$product-variations-carousel-link-selected-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;

// Disabled state
$product-variations-carousel-link-disabled-opacity: 0.8;
$product-variations-carousel-link-disabled-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-disabled-selected-border: rem-calc(2) solid color(primary);
$product-variations-carousel-disabled-price-value-color: color(disabled);

// Price
$product-variations-carousel-price-color: color(text-secondary);
$product-variations-carousel-selected-price-value-font-weight: 700;
$product-variations-carousel-selected-price-value-color: color(primary-active);

@import "@lora/05-components/product/product-variations-carousel";