// Product Name
$product-main-v2-name-font: #{rem-calc(26)} / rem-calc(30) $font-secondary;
$product-main-v2-name-font--large: #{rem-calc(34)} / rem-calc(38) $font-secondary;

// Product Subtitle
$product-main-v2-subtitle-font: 500 #{rem-calc(14)} / 1.4 $font-primary;
$product-main-v2-subtitle-font--large: $product-main-v2-subtitle-font;
$product-main-v2-subtitle-text-transform: uppercase;
$product-main-v2-subtitle-text-transform--large: uppercase;
$product-main-v2-subtitle-margin: rem-calc(0 0 10);

// Layout
$product-main-v2-info-text-align: left;
$product-main-v2-info-text-align--large: left;
$product-main-v2-padding--large: rem-calc(40 0);
$product-main-v2-image-order: 0;
$product-main-v2-image-order--large: -1;

// Rating
$product-main-v2-rating-justify-content: left;
$product-main-v2-rating-justify-content--large: left;

// Product Price
$product-main-v2-price-border-bottom: 0;
$product-main-v2-price-border-bottom--large: $product-main-v2-price-border-bottom;
$product-main-v2-price-padding: 0;
$product-main-v2-price-padding--large: 0;
$product-main-v2-price-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary;
$product-main-v2-price-font--large: $product-main-v2-price-font;
$product-main-v2-price-margin: rem-calc(0 0 10);

// Availability
$product-main-v2-availability-justify-content: left;
$product-main-v2-availability-label-color: color(primary-active);

// Delivery date message
$product-main-v2-delivery-date-message-flex-direction: column;
$product-main-v2-delivery-date-message-flex-direction--large: row;
$product-main-v2-delivery-date-message-justify-content: flex-start;
$product-main-v2-delivery-date-message-justify-content--large: $product-main-v2-delivery-date-message-justify-content;
$product-main-v2-delivery-date-message-align-items: flex-start;
$product-main-v2-delivery-date-message-align-items--large: center;

@import "@lora/05-components/product/product-main-v2";