@import "@root/05-components/product/product-main";

$product-main-name-text-transform: none;

@mixin jp-lancome-components-product-main {
    .c-product-main__rating {
        .c-product-main__review {
            display: flex;
        }
    }
}