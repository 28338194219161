$product-sticky-bar-title-font-size: rem-calc(14);
$product-sticky-bar-title-font-weight: 500;
$product-sticky-bar-title-text-transform: uppercase;
$product-sticky-bar-item-image-border: none;
$product-sticky-bar-letter-spacing: rem-calc(0.26);
$product-sticky-bar-description-text-transform: none;
$product-sticky-bar-description-color: color(text-secondary);
$product-sticky-bar-wishlist-text-transform: capitalize;
$product-sticky-bar-wishlist-link-font-size: rem-calc(14);
$product-sticky-bar-wishlist-link-font-weight: 500;
$product-sticky-bar-actions-margin--medium-down: rem-calc(0 5 5);
$product-sticky-bar-availability-label-color: color(primary-active);
$product-sticky-bar-availability-label-text-transform: none;

@import "@lora/05-components/product/product-sticky-bar";