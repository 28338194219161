// Tabs specific style
$product-swatches-grouped-tab-margin: rem-calc(0 5);
$product-swatches-grouped-tab-color: color(primary-active);
$product-swatches-grouped-tab-background: color(light);
$product-swatches-grouped-tab-font: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$product-swatches-grouped-tab-padding: rem-calc(7 10 5 10);
$product-swatches-grouped-tab-padding--large: $product-swatches-grouped-tab-padding;
$product-swatches-grouped-tabs-control-items-margin: rem-calc(15 0 10);
$product-swatches-grouped-tab-border: 1px solid color(global-background);
$product-swatches-grouped-tabs-controls-align-self--large: flex-start;

// Secondary toggle
$product-swatches-grouped-toggle-secondary-font: 300 #{rem-calc(12)} / #{rem-calc(18)} $font-primary !default;
$product-swatches-grouped-toggle-secondary-font--large: $product-swatches-grouped-toggle-secondary-font;

// Swatches Group
$product-swatches-grouped-group-margin: rem-calc(0 0 15);
$product-swatches-grouped-group-margin--large: $product-swatches-grouped-group-margin;

// Swatch
$product-swatches-grouped-swatch-margin: rem-calc(0 8 15);

@import "@lora/05-components/product/product-swatches-grouped";