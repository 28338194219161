$product-review-content-word-break: break-word;
$product-review-padding: rem-calc(20 20 30);
$product-review-padding--large: rem-calc(20 20 36 18);
$product-review-margin: rem-calc(0 0 10);
$product-review-margin--large: rem-calc(0 0 12);

// Title
$product-review-title-margin: rem-calc(11 0 11);
$product-review-title-margin--large: rem-calc(11 0 11);

// Toggler
$product-review-toggler-font: 300 #{rem-calc(12)} / rem-calc(16) $font-primary-light;
$product-review-toggler-text-transform: none;
$product-review-toggler-border-bottom: 1px solid color(disabled);

// Userstats item
$product-review-userstats-value-margin: rem-calc(0 0 0 17);
$product-review-userstats-margin--large: rem-calc(0 15 0 0);
$product-review-userstats-item-padding: rem-calc(3 0 3);
$product-review-userstats-item-padding--large: rem-calc(3 0 3);
$product-review-padding--large: rem-calc(20 20 35);
$product-review-margin--large: rem-calc(0);

// Message
$product-review-message-margin: rem-calc(0 0 22);
$product-review-message-margin--large: rem-calc(0 0 40);

// Userstats
$product-review-userstats-border-bottom--large: none;

// Action
$product-review-action-title-margin: rem-calc(27 0 12);
$product-review-action-title-margin--large: rem-calc(27 0 9);

// Message text
$product-review-message-text-margin: 0;

$product-review-image-width: rem-calc(60);
$product-review-image-width--large: rem-calc(183);

$product-review-info-verifed-icon-size: rem-calc(20);

@import "@lora/05-components/product/product-review";