$selected-products-title-font: 500 #{rem-calc(12)} / rem-calc(20) $font-tertiary;
$selected-products-title-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-tertiary;
$selected-products-title-color: color(organism-background);
$selected-products-title-color--large: $selected-products-title-color;
$selected-products-item-border: 1px solid color(border);
$selected-products-item-border--large: $selected-products-item-border;
$selected-products-item-active-border: rem-calc(2) solid color(dark);
$selected-products-item-active-border--large: $selected-products-item-active-border;
$selected-products-total-color: color(light);
$selected-products-total-color--large: $selected-products-total-color;
$selected-products-total-background: color(dark);
$selected-products-total-background--large: $selected-products-total-background;
$selected-products-total-font: #{rem-calc(14)} / 1.2 $font-primary;
$selected-products-total-font--large: $selected-products-total-font;
$selected-products-error-margin--large: rem-calc(5 20);

@import "@lora/05-components/selected-products";