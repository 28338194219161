$social-share-item-border-radius--large: 50%;
$social-share-font-size: rem-calc(14);
$social-share-first-item-border--large: 0;
$social-share-large-icon-size: rem-calc(30);
$social-share-large-icon-size--large: $social-share-large-icon-size;
$social-share-item-border: none;
$social-share-item-padding--large: rem-calc(10 5 10);
$social-share-text-transform: capitalize;
$social-share-icon-size: rem-calc(19);
$social-share-item-display: table;
$social-share-icon-hover-color: color(secondary-active);
$social-share-xlarge-item-margin: rem-calc(0 15 0 0);
$social-share-xlarge-icon-size: rem-calc(25);
$social-share-xlarge-icon-size--large: rem-calc(25);

@import "@lora/05-components/social-share";