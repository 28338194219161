$search-refinements-border-top: none;

// Search refinements header
$search-refinements-header-title-font: 100 #{rem-calc(12)} / rem-calc(16) $font-primary;
$search-refinements-header-title-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-tertiary;
$search-refinements-header-padding--large: rem-calc(22 0 30 0);
$search-refinements-header-title-text-transform--large: uppercase;

// Category Name
$search-refinements-category-name-padding: rem-calc(20 50 20 0);
$search-refinements-category-name-margin: rem-calc(0 0 20);
$search-refinements-category-name-font: 500 #{rem-calc(12)} / rem-calc(16) $font-tertiary;
$search-refinements-category-name-color: color(primary-active);
$search-refinements-category-name-text-transform: none;
$search-refinements-footer-margin: rem-calc(0 0 10);

@import "@lora/05-components/search/search-refinements";