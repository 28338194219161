$video-library-item-name-text-transform: uppercase;
$video-library-item-name-text-transform--large: $video-library-item-name-text-transform;
$video-library-item-name-font-family: $font-primary;
$video-library-item-name-margin--large: rem-calc(0 0 8 0);
$video-library-item-name-color: color(dark);
$video-library-item-description-color--large: $video-library-item-name-color;
$video-library-item-name-font-size: rem-calc(14);
$video-library-item-name-line-height: 1.43;
$video-library-item-name-letter-spacing: 0;
$video-library-item-name-font-weight: 400;
$video-library-item-name-font-weight--large: 500;
$video-library-item-description-font-weight: normal;
$video-library-item-description-font-weight--large: $video-library-item-description-font-weight;
$video-library-item-description-max-lines--large: 2;
$video-library-item-description-line-height--large: 1.5;
$video-library-item-description-font-size--large: rem-calc(14);
$video-library-item-description-height--large: $video-library-item-description-line-height--large*$video-library-item-description-font-size--large*$video-library-item-description-max-lines--large;

$video-library-item-icon-color: color(dark);
$video-library-item-icon-height: rem-calc(25);
$video-library-item-icon-height--large: rem-calc(33);
$video-library-item-icon-width: rem-calc(25);
$video-library-item-icon-width--large: rem-calc(33);

$video-library-item-background--medium-down: color(primary-active);

@import "@lora/05-components/video-library";