$video-asset-info-name-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-primary;
$video-asset-info-name-letter-spacing: rem-calc(0.26);
$video-asset-info-margin--large: rem-calc(15 0 0);
$video-asset-info-name-color: color(dark);
$video-asset-info-description-color: color(dark);
$video-asset-info-name-text-transform: none;
$video-asset-icon-color: color(light);
$video-asset-icon-height: rem-calc(33);
$video-asset-icon-height--large: $video-asset-icon-height;
$video-asset-icon-width: rem-calc(33);
$video-asset-icon-width--large: $video-asset-icon-width;

// Video asset autoplay button
$video-asset-control-button-icon-hover-color: color(secondary-active);

@import '@lora/05-components/video-asset';