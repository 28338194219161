
                            $is-app_autoreplenishment-enabled: true;
                            $replenishment-selector-item-background: transparent;
$replenishment-selector-label-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
// Content
$replenishment-selector-content-margin: rem-calc(26 0 0);

// Price
$replenishment-selector-price-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$replenishment-selector-price-old-font: 400 #{rem-calc(14)} / #{rem-calc(16)} $font-primary;
$replenishment-selector-price-old-margin: rem-calc(0 6 0 0);

@import "@root/05-components/replenishment-selector";
                        