$product-detail-image-mosaic-height: auto;
$product-detail-image-mosaic-padding: rem-calc(15 0);
$product-detail-image-mosaic-services-spacing: rem-calc(10);
$product-detail-image-mosaic-margin: rem-calc(0 -15);
$product-detail-image-wishlist-top: rem-calc(20);
$product-detail-image-wishlist-top--large: 0;
$product-detail-image-mosaic-item-padding: rem-calc(0 0 15 15);
$product-detail-image-mosaic-services-margin: rem-calc(10 0 -15);
$product-detail-image-mosaic-services-justify-content: center;
$product-detail-image-view360-right: rem-calc(16);
$product-detail-image-view360-top: rem-calc(70);
$product-detail-image-view360-top--large: 0;
$product-detail-image-zoom-right--large: rem-calc(20);
$product-detail-image-zoom-display: none;
$product-detail-image-zoom-display--large: inline-block;
$product-detail-image-badge-large-top: rem-calc(20);
$product-detail-image-view360-right--large: calc(50% + #{rem-calc(10)});

// Horizontal carousel
$product-detail-image-alternatives-horizontal-margin: rem-calc(15) auto;
$product-detail-image-alternatives-horizontal-max-width--large: rem-calc(670);
$product-detail-image-alternatives-horizontal-padding: rem-calc(15 45 0);

// Horizontal carousel small
$product-detail-image-alternatives-horizontal-small-max-width: rem-calc(320);
$product-detail-image-alternatives-horizontal-small-margin: rem-calc(20) auto;
$product-detail-image-alternatives-horizontal-small-padding: rem-calc(0 25);

@import "@lora/05-components/product/product-detail-image";